import ILanguage from './ILanguage';

const es: ILanguage = {
    title: 'Idioma',
    portuguese: 'Portugués',
    english: 'Inglés',
    spanish: 'Español',
    prev: 'previa',
    next: 'siguiente',
    send: 'Enviar',
    thankYouPageLeftTitle: 'Búsqueda de satisfacción',
    thankYouPageRightTitle: 'Gracias',
    thankYouPageText: '¡Su opinión es muy importante para nosotros!',
    of: 'de',
    developedBy: 'Desarrollado por',
    requiredField: 'Campo obligatorio',
    requiredQuestionLabel: '(obligatorio)',
    optionalQuestionLabel: '(opcional)',
    staticClassForSandbox: 'Clases estáticas para entorno de prueba',
    AutoCompleteLoadingText: 'Cargando por favor espere',
    AutoCompleteNoOptionsText: 'No hay opción disponible',
    AutoCompleteNoResultsText: 'Ningún resultado encontrado',
    AutoCompleteRetryTitle: 'Intentar nuevamente',
    AutoCompleteSearchPromptText: 'Escriba para cargar opciones',
    environmentSandbox: 'Formulario de prueba, solo para visualización',
    alreadyCompletedForm: 'Ya has completado este formulario.',
};

export default es;
