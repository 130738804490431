
import LanguageSelectorDropdown from '@/app/components/LanguageSelectorDropdown.vue';
import Skeleton from '@/app/components/Skeleton.vue';
import ColumnHeader from '@/app/Survey/components/ColumnHeader.vue';
import DefaultAlert from '@/app/Survey/components/DefaultAlert.vue';
import DefaultContainer from '@/app/Survey/components/DefaultContainer.vue';
import Logotype from '@/app/Survey/components/Logotype.vue';
import MainLoading from '@/app/Survey/components/MainLoading.vue';
import PageSteps from '@/app/Survey/components/PageSteps.vue';
import CustomStylor from '@/app/Survey/config/CustomStylor';
import { themeByConfig } from '@/app/Survey/config/ThemeByConfig';
import FormRegisterComponent from '@/app/Survey/FormRegisterComponent';
import DataUrl from '@/app/Survey/model/DataUrl';
import MobileLayout from '@/app/Survey/model/MobileLayout';
import Steps from '@/app/Survey/model/Steps';
import { IConfig, ITheme, IThemeConfig } from '@/app/Survey/model/Theme';
import FormStateResponse from '@/dtos/FormStateResponse';
import IAlertConfig from '@/dtos/IAlertConfig';
import { getAnswers, getPage, removeData, setAnswer, setAnswers, setPage } from '@/helpers/LocalStorageService';
import FormService from '@/services/FormService';
import { FormStructure, IAnswer, IColumn, IComponent, IPage, IQuestions, IRadioAnswer, IResponseAnswer } from '@/services/FormStructure';
import JourneyParameters, { JourneyParametersDefault } from '@/services/JourneyParameters';
import JourneyService from '@/services/JourneyService';
import DynamicLDService from '@/services/DynamicLDService';
import _ from 'lodash';
import { Component, Inject, Mixins } from 'vue-property-decorator';
import Conditional from './model/conditionals/Conditional';
import ConditionalDto, { ConditionQuestionStateDto } from './model/conditionals/ConditionalDto';
import { FormComponentsType } from './model/Form';
import { Cancel } from 'axios';
import { component } from 'vue/types/umd';

@Component({ components: { Skeleton, ColumnHeader, PageSteps, LanguageSelectorDropdown, DefaultAlert, DefaultContainer, MainLoading, Logotype } })
export default class Form extends Mixins(FormRegisterComponent) {
    @Inject() public formService!: FormService;
    @Inject() public journeyService!: JourneyService;
    @Inject() public dynamicLDService!: DynamicLDService;

    public urlData: DataUrl = null;
    public questionsIdToRemove: number[] = [];
    public isFormLoading: boolean = false;
    public journeyParameters: JourneyParameters = {};
    public formStructure: FormStructure = {
        title: '',
        instanceName: '',
        config: {
            mobileLayout: MobileLayout.default,
            showLogotype: true,
            logo: '',
            pages: [],
        },
        appearance: {
            themes: [],
            mainColor: '',
        },
        createdAt: '',
        employeeId: 0,
        id: 0,
        updatedAt: '',
    };

    public questions: IQuestions = { answers: [] };
    public alertVisibility: boolean = false;
    public alertOptions: IAlertConfig = { status: '', type: '', text: '' };
    public width: number = 0;
    public customStylor: CustomStylor = null;
    public isMissingRequiredQuestion: boolean = false;
    public isLoading: boolean = false;

    public get isStyleLoading(): boolean {
        return this.customStylor?.isLoading ?? false;
    }

    public get pagesAmount(): number {
        return this.formStructure.config.pages.length;
    }

    public get pagesAmountWithoutEndingPage(): number {
        return this.formStructure.config.pages.filter(page => page.pageType !== 'endPage').length;
    }

    public get currentPage(): number {
        return parseInt((this.$route.query.page as string) ?? '1');
    }

    public get hasEndingPage(): boolean {
        return !!this.formStructure.config.pages.find(page => page.pageType === 'endPage');
    }

    public get hasPagesAndIsNotEndingPage(): boolean {
        return this.pagesAmountWithoutEndingPage > 1 && !this.isCurrentPageEndingPage;
    }

    public get isOnLastPage(): boolean {
        return this.currentPage === this.pagesAmountWithoutEndingPage;
    }

    public set currentPage(page: number) {
        if (page === Number(this.$route?.query?.page)) {
            return;
        }

        this.$router.replace({
            name: 'page',
            query: {
                ...this.$route.query,
                page: page.toString(),
            },
            params: {
                ...this.$route.params,
            },
        });
    }

    public get transactionReference(): number | string {
        return this.urlData.transactionReference;
    }

    public get currentBuilderPage(): IPage {
        return this.formStructure.config.pages[this.currentPage - 1];
    }

    public get formReady(): boolean {
        return Boolean(this.formStructure.config.pages.length && this.currentBuilderPage.columns.length) && !this.isStyleLoading;
    }

    public get currentConfigTheme(): IConfig {
        if (this.currentBuilderPage && this.currentBuilderPage.currentThemeId) {
            return this.formStructure.appearance.themes.find((theme: IThemeConfig) => theme.id === this.currentBuilderPage.currentThemeId)?.config;
        }
        return null;
    }

    public get theme(): ITheme | {} {
        return themeByConfig(this.currentConfigTheme);
    }

    public get shouldShowOnlyRequiredLabels(): boolean {
        return this.haveMoreOrEqualOptionalThanRequiredQuestionsAndAtLeastOneRequiredQuestion() || !this.totalOfOptionalQuestions();
    }

    public get haveAtLeastOneRequiredQuestion(): boolean {
        return !!this.totalOfRequiredQuestions();
    }

    public get isCurrentPageEndingPage(): boolean {
        return this.hasEndingPage && this.currentPage === this.pagesAmount;
    }

    public get isEnvironmentSandbox(): boolean {
        return this.urlData.environment === 'sandbox';
    }

    public created(): void {
        window.addEventListener('resize', this.windowResized);
    }

    public destroyed(): void {
        window.removeEventListener('resize', this.windowResized);
    }

    public async mounted() {
        this.customStylor = new CustomStylor(document);
        this.urlData = new DataUrl(this.$route);
        await this.validateAvailability();
        await this.getFormById();
        this.windowResized();
        await this.populateSandboxDataDynamicLikeDislike();
    }

    private async populateSandboxDataDynamicLikeDislike(): Promise<void> {
        if (this.isEnvironmentSandbox) {
            for (const page of this.formStructure.config.pages) {
                for (const column of page.columns) {
                    for (const component of column.components) {
                        if (component.settings?.childrenType === 'DynamicLikeDislikeComponent') {
                            component.children = await this.getSandboxDynamicLDClasses(component.id);
                        }
                    }
                }
            }

            this.updateFormByLocalData();
        }
    }

    private async getSandboxDynamicLDClasses(questionId: number): Promise<IComponent[]> {
        return await this.dynamicLDService.getSandboxDynamicLD(questionId);
    }

    public sortComponentsByOrder(components: IComponent[]): IComponent[] {
        if (!components || !components.length) return components;

        return _.sortBy(components, ['order']);
    }

    public setLang(selectedLang: string): void {
        this.$i18n.locale = selectedLang;
    }

    private async validateAvailability() {
        try {
            const formState = await this.formService.validateFormAvailability(this.urlData.formValidationSet);
            if (formState.isRatingCompleted) {
                this.$router.replace({
                    name: 'alreadyAnswered',
                    params: { ...this.$route.params },
                });
            }
            if (this.urlData.isUsingExternalReference) {
                this.overwriteSurveySet(formState);
            }
        } catch (err) {
            console.error(err);
        }
    }

    private overwriteSurveySet(formState: FormStateResponse): void {
        this.urlData = new DataUrl({
            ...this.$route,
            query: {
                ...this.$route.query,
                transactionId: String(formState.transactionId),
                attemptId: String(formState.attemptId),
            },
        });
    }

    public windowResized(): void {
        this.width = document.documentElement.clientWidth;
    }

    public shouldShowItsSelf(conditionals: Conditional[]): boolean {
        if (!conditionals || conditionals.length === 0) {
            return true;
        }

        if (conditionals?.some((item: Conditional) => item?.action === 'hide')) {
            return !conditionals?.some((item: Conditional) => item?.shouldShowItsSelf() === false);
        }

        return conditionals?.some((item: Conditional) => item?.shouldShowItsSelf() === true);
    }

    public setCornerOrientation(columnSide: number) {
        if (this.currentBuilderPage.columns.length === 1) {
            return 'rounded';
        }

        if (columnSide === 1) return 'rounded-left';
        if (columnSide === 2) return 'rounded-right';
    }

    public setAnswerAsArrayWhenIsMultiple(): void {
        this.formStructure.config.pages.forEach(page => {
            page.columns.forEach(column => {
                column.components.forEach(component => {
                    if (component?.settings?.selectionStrategy === 'multiple') {
                        component.answer = [];
                    }
                });
            });
        });
    }

    public async getFormById(): Promise<void> {
        this.isFormLoading = true;
        try {
            const journeyParameters = await this.journeyService.getJourneyParametersByTransationId(Number(this.urlData!.transactionId));
            this.journeyParameters = this.getJourneyParameters(journeyParameters);
            this.formStructure = await this.formService.getFormById(Number(this.urlData!.formId), Number(this.urlData!.transactionId));

            this.setCustomBootstrapImport();
            this.setAnswerAsArrayWhenIsMultiple();
            //@ts-ignore
            this.$root.instanceName = this.formStructure.instanceName;
            this.formationQuestions();
        } catch (err) {
            console.error(err);
            this.triggerAlert('danger', 'Error', '');
        } finally {
            if (getAnswers(this.transactionReference, this.urlData!.formId)) {
                this.updateFormByLocalData();
            }
            if (getPage(this.transactionReference, this.urlData!.formId)) {
                if (this.formStructure.config.pages.length < getPage(this.transactionReference, this.urlData!.formId)) {
                    setPage(this.transactionReference, 1, this.urlData!.formId);
                }
                this.currentPage = getPage(this.transactionReference, this.urlData!.formId);
            }

            // Prepare conditionals
            this.buildConditionals();
            this.isFormLoading = false;
        }
    }

    public normalizeComponent(component: IComponent): Omit<IComponent, 'conditionals'> {
        const cleanedComponent = JSON.parse(JSON.stringify(component));
        delete cleanedComponent.conditionals;
        return cleanedComponent;
    }

    private getQuestion(): IComponent[] {
        // Page > Column > Component(Question, text, label etc)
        return this.formStructure.config.pages.flatMap((page: IPage) => page.columns.flatMap((column: IColumn) => column.components.flatMap((item: IComponent) => item)));
    }

    public splitConditionsByElementType(): ConditionalDto[] {
        const questionList = this.getQuestion();
        questionList.push(...questionList.flatMap(component => component.children ?? []));
        const conditionals = this.formStructure?.conditionals || [];

        const questionElement = [] as ConditionalDto[];
        conditionals.forEach((conditional: ConditionalDto, index: number) => {
            if (conditional.conditions.length > 0) {
                conditional.conditions.forEach((element: ConditionQuestionStateDto) => {
                    element.questionElement = questionList.filter(item => item.id === element.questionId)[0];
                });
                questionElement.push({ ...conditional, index });
            }
        });
        return questionElement;
    }

    public buildConditionals(): void {
        this.formStructure.config.pages.forEach((page: IPage) => {
            this.assignsConditionalsToPages(page);
            this.assignsConditionalsToQuestion(page);
        });
    }

    private assignsConditionalsToPages(page: IPage): void {
        const conditionals = this.formStructure?.conditionals || [];
        const pageConditionals = this.splitConditionsByElementType();
        page['conditionals'] = [];
        pageConditionals.forEach((item: ConditionalDto) => {
            if (item.trigger.id == page.id) {
                page['conditionals'].push(new Conditional(conditionals[item.index]));
            }
        });
    }

    private assignsConditionalsToQuestion(page: IPage): void {
        const conditionals = this.formStructure?.conditionals || [];
        const questionsConditionals = this.splitConditionsByElementType();
        const componentList = page.columns.flatMap((column: IColumn) => column.components.flatMap((item: IComponent) => item));
        componentList.forEach((component: IComponent) => {
            component['conditionals'] = [];
            questionsConditionals.forEach((conditional: ConditionalDto) => {
                if (conditional.targets.map(t => t.id).includes(component.id)) {
                    const newConditional = new Conditional(conditionals[conditional.index]);
                    component['conditionals'].push(newConditional);
                }
            });
        });
    }

    private handleBehaviorOnChangedFormPage(element: IPage): string {
        // return !this.shouldShowItsSelf(element?.conditionals ?? []) ? 'NEXT_PREVIOUS_PAGE' : 'NORMAL';
        return 'NORMAL';
    }

    public getPageBasedOnConditionals(step: string): number {
        const objPageIndex = { NORMAL: 1, NEXT_PREVIOUS_PAGE: 2 };
        const element: IPage = step === Steps.next ? this.getPageById(this.currentPage) : this.getPageById(this.currentPage - 1);
        const value = this.handleBehaviorOnChangedFormPage(element);
        return step === Steps.next ? this.currentPage + objPageIndex[value] : this.currentPage - objPageIndex[value];
    }

    public getPageById(currentPage: number): IPage {
        const pagesAmount = this.formStructure.config.pages.length;
        if (currentPage < 1) currentPage = 1;
        if (currentPage >= pagesAmount) currentPage = pagesAmount;
        const objPage = this.formStructure?.config?.pages.filter(page => page.page === currentPage)[0];
        return objPage ?? ({} as IPage);
    }

    public pageSelect(step: string): void {
        if (this.haveAnyRequiredQuestionToAnswer() && step !== Steps.previous) {
            this.setIsMissingRequiredField();
            this.scrollToRequiredQuestion();
            return;
        }

        this.currentPage = this.getPageBasedOnConditionals(step);
        setPage(this.transactionReference, this.currentPage, this.urlData!.formId);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    public setCustomBootstrapImport(): void {
        const color = this.formStructure.appearance.mainColor ?? '#0C5A7A';
        this.customStylor.mountStyle(encodeURIComponent(color));
    }

    public handleMobileColumnsOrder(columnNumber: number): string {
        const order: string = 'order-1';
        if (this.currentBuilderPage.columns.length > 1 && columnNumber === 1 && this.formStructure.config.mobileLayout === MobileLayout.secondUp) {
            return order;
        }
        return '';
    }

    public handleMobileHideColumn(columnNumber: number): boolean {
        if (this.currentBuilderPage.columns.length > 1 && this.width <= 415) {
            return !(
                (this.formStructure.config.mobileLayout === MobileLayout.hideFirstColumn && columnNumber === 1) ||
                (this.formStructure.config.mobileLayout === MobileLayout.hideSecondColumn && columnNumber === 2)
            );
        }
        return true;
    }

    public formationQuestions(): void {
        if (this.transactionReference) {
            this.questions['transactionId'] = Number(this.transactionReference);
            this.questions['attemptId'] = Number(this.urlData.attemptId);
        } else {
            this.questions = {
                ...this.questions,
                ...this.urlData.query,
            };
        }
        this.questions['formId'] = this.urlData.formId;
    }

    public handleUpdatedComponent(updatedComponent: IComponent): void {
        for (const column of this.currentBuilderPage.columns) {
            column.components = column.components.map((component: IComponent) => (this.isComponentTheSame(component, updatedComponent) ? updatedComponent : component));
        }
        this.writeAnswer(updatedComponent);
        this.buildConditionals();
    }

    private isComponentTheSame(component: IComponent, componentToCompare: IComponent): boolean {
        return component.id === componentToCompare.id && component.type === componentToCompare.type;
    }

    public writeAnswer(updatedComponent: IComponent): void {
        this.questionsIdToRemove = [];
        this.questions['formId'] = this.urlData.formId;
        if (Array.isArray(updatedComponent?.answer) && updatedComponent.settings.selectionStrategy === 'multiple') {
            this.questions.answers = [];
            updatedComponent?.answer.forEach((item: IAnswer) => {
                const questionAnswer = {
                    formQuestionId: updatedComponent.id,

                    ...item,
                };

                if (item?.id) {
                    questionAnswer.id = (updatedComponent.answer as IAnswer).id;
                }

                this.questions.answers.push({ ...questionAnswer });
            });

            if (updatedComponent.answer.length === 0) {
                this.questionsIdToRemove.push(updatedComponent.id);
                this.questions.answers = [{ formQuestionId: updatedComponent.id, answer: null, formId: this.urlData.formId }];
            }
        } else {
            const objQuestionsAnswer = {
                formQuestionId: updatedComponent.id,
                formId: this.urlData.formId,
                ...updatedComponent.answer,
            };
            this.questions.answers = [objQuestionsAnswer];

            if (updatedComponent.parentId) {
                this.mountChildrenAnswer(updatedComponent);

                const questions = this.getQuestion();
                const componentIndex = questions.findIndex((item: IComponent) => item.id === updatedComponent.parentId);
                this.setParentGroupChildAnswers(questions[componentIndex], objQuestionsAnswer as IAnswer);
            }

            if (!Array.isArray(updatedComponent?.answer) && updatedComponent.answer.id) this.questions.answers[0]['id'] = (updatedComponent.answer as IAnswer).id;
        }

        this.sendAnswer(updatedComponent);
    }

    private mountChildrenAnswer(updatedComponent: IComponent): void {
        this.questions.answers[0]['parentId'] = updatedComponent.parentId;
        this.questions.answers[0]['isClass'] = updatedComponent.isClass;
        if (updatedComponent.isClass) {
            this.questions.answers[0]['questionTitle'] = updatedComponent.content.title;
            this.questions.answers[0]['classId'] = updatedComponent.classId;
            this.questions.answers[0]['classOrder'] = updatedComponent.classOrder;
        }
    }

    public async sendAnswer(updatedComponent: IComponent): Promise<void> {
        try {
            const answer: IAnswer[] = await this.saveAnswer(updatedComponent);

            setAnswer(this.transactionReference, answer, updatedComponent?.id, this.urlData!.formId);
            await this.undoPartialAnswerInStorage();
            if (updatedComponent.settings?.selectionStrategy === 'multiple') {
                updatedComponent.answer = answer;
            } else {
                updatedComponent.answer = answer[0];
            }
        } catch (error) {
            if ((error as Cancel).message !== 'Canceled') {
                const errorMessage = 'Error save answer';
                console.error(errorMessage, error);
                this.triggerAlert('danger', errorMessage, '');
            }
        }
    }

    public async saveAnswer(updatedComponent: IComponent): Promise<IAnswer[]> {
        let answer: IAnswer[] = [];
        if (this.journeyParameters?.saveInitialRating) {
            if (this.isButtonSelectComponentAndSingleAnswer(updatedComponent)) this.formService.cancelSaveAnswer();
            answer = await this.formService.saveAnswer(this.questions);
        } else {
            answer = this.generateLocalAnswers(this.questions);
        }
        return answer;
    }

    private isButtonSelectComponentAndSingleAnswer(updatedComponent: IComponent): boolean {
        return updatedComponent.type === FormComponentsType.ButtonSelectComponent && updatedComponent.settings?.selectionStrategy === 'single';
    }

    public generateLocalAnswers(data: IQuestions): IResponseAnswer[] {
        const uniqueId = Date.now();
        return data.answers.reduce((newAnswer, answer) => {
            newAnswer.push({
                id: uniqueId,
                answer: answer.answer,
                formQuestionId: answer.formQuestionId,
                text: answer.text,
                parentId: answer?.parentId,
                transactionId: data.transactionId,
                attemptId: data.attemptId,
                classId: answer.classId,
                classOrder: answer.classOrder,
                questionTitle: answer.questionTitle,
                isClass: answer.isClass,
                formId: this.urlData.formId,
            });
            return newAnswer;
        }, []);
    }

    public async sendAllAnswers(localAnswers: IResponseAnswer[]): Promise<IResponseAnswer[]> {
        const transactionId = JSON.parse(JSON.stringify(localAnswers[0]?.transactionId));
        const attemptId = JSON.parse(JSON.stringify(localAnswers[0]?.attemptId || null));
        localAnswers.forEach((item: IResponseAnswer) => delete item.id);
        const answer = {
            answers: localAnswers,
            transactionId,
            attemptId,
            formId: this.urlData.formId,
        } as IQuestions;
        return await this.formService.saveAnswer(answer);
    }

    public async handleSendAllAnswers(): Promise<void> {
        const localAnswersList = getAnswers(this.transactionReference, this.urlData!.formId) as IResponseAnswer[][];
        await this.sendAllAnswers(localAnswersList.flat());
    }

    public async sendForm(): Promise<void> {
        if (this.haveAnyRequiredQuestionToAnswer()) {
            this.setIsMissingRequiredField();
            this.scrollToRequiredQuestion();
            return;
        }

        try {
            this.isLoading = true;
            if (this.transactionReference) {
                if (!this.journeyParameters.saveInitialRating) {
                    await this.handleSendAllAnswers();
                }
                await this.formService.sendFormForComplete(this.urlData.formCompleteSet);
            }

            await this.handleEndingPage();
            this.isLoading = false;
            removeData(this.transactionReference, this.urlData!.formId);
        } catch (error) {
            console.error('Error...', error);
            this.isLoading = false;
            this.triggerAlert('danger', 'Error on complete form', '');
        }
    }

    private async handleEndingPage(): Promise<void> {
        if (this.hasEndingPage) {
            this.goToEndingPage();
        } else {
            await this.goToDefaultEndingPage();
        }
    }

    private goToEndingPage(): void {
        this.currentPage = this.getEndingPage().page;
    }

    private async goToDefaultEndingPage(): Promise<void> {
        await this.$router.push({
            name: 'defaultEndingPage',
            params: { ...this.$route.params },
        });
    }

    private getEndingPage(): IPage {
        return this.formStructure.config.pages.find(page => page.pageType === 'endPage');
    }

    private haveAnyRequiredQuestionToAnswer(): boolean {
        this.isMissingRequiredQuestion = false;

        return this.checkIfHaveRequiredQuestion(this.getNotAnsweredQuestions()) || this.checkIfHaveRequiredQuestion(this.getNotAnsweredParentGroupQuestions());
    }

    private checkIfHaveRequiredQuestion(questions: IComponent[]): boolean {
        return questions.some(question => question?.settings?.isRequired && this.isThisQuestionRequired(question));
    }

    private getNotAnsweredQuestions(): IComponent[] {
        return this.currentBuilderPage.columns.flatMap(column =>
            column.components
                .filter(component => component.type !== FormComponentsType.ParentGroupComponent)
                .filter(component => (Array.isArray(component.answer) && !component.answer.some(an => an.answer)) || !component?.answer || this.isObjectAnswerEmpty(component?.answer)),
        );
    }

    private getNotAnsweredParentGroupQuestions(): IComponent[] {
        return this.currentBuilderPage.columns.flatMap(column =>
            column.components
                .filter(component => component.type === FormComponentsType.ParentGroupComponent)
                .filter(component => component.children.some(child => this.isObjectAnswerEmpty(child.answer))),
        );
    }

    private isObjectAnswerEmpty(objectAnswer: IAnswer | IAnswer[] | IRadioAnswer): boolean {
        return Object.keys(objectAnswer || {}).length === 0;
    }

    private setIsMissingRequiredField(): void {
        this.isMissingRequiredQuestion = this.haveAnyRequiredQuestionToAnswer();
    }

    public scrollToRequiredQuestion(): void {
        setTimeout(() => {
            const invalidFeedbackStyleElement = document.getElementsByClassName('required-field')[0];
            if (invalidFeedbackStyleElement) {
                invalidFeedbackStyleElement.scrollIntoView({ behavior: 'smooth' });
            }
        }, 200);
    }

    public isThisQuestionRequired(component: IComponent): boolean {
        return (component.settings?.isRequired || false) && this.shouldShowItsSelf(component.conditionals);
    }

    private haveMoreOrEqualOptionalThanRequiredQuestionsAndAtLeastOneRequiredQuestion(): boolean {
        return this.haveMoreOrEqualOptionalThanRequiredQuestions() && !!this.totalOfRequiredQuestions();
    }

    private haveMoreOrEqualOptionalThanRequiredQuestions(): boolean {
        return this.totalOfRequiredQuestions() <= this.totalOfOptionalQuestions();
    }

    private totalOfRequiredQuestions(): number {
        return this.currentBuilderPage.columns.map(column => column.components.map(component => component?.settings?.isRequired))[0].filter(questions => questions === true).length;
    }

    private totalOfOptionalQuestions(): number {
        return this.currentBuilderPage.columns.map(column => column.components.map(component => component?.settings?.isRequired))[0].filter(questions => questions !== true).length;
    }

    public getJourneyParameters(parameters: JourneyParameters): JourneyParameters {
        const journeyParametersDefault = new JourneyParametersDefault();
        Object.keys(journeyParametersDefault).forEach(parameterName => {
            if (parameters[parameterName] === null || parameters[parameterName] === undefined) {
                parameters[parameterName] = journeyParametersDefault[parameterName];
            }
        });
        return parameters;
    }

    public updateFormByLocalData(): void {
        for (const pages of this.formStructure.config.pages) {
            for (const column of pages.columns) {
                for (const component of column.components) {
                    const localAnswersList = getAnswers(this.transactionReference, this.urlData!.formId) as IAnswer[][];

                    localAnswersList.forEach((answers: IAnswer[]) => {
                        answers.forEach((localAnswer: IAnswer) => {
                            const { answer, settings } = component;

                            const isSameComponent = localAnswer.formQuestionId === component.id;
                            const isChildAnswer = localAnswer?.parentId === component.id;

                            // Get answer already done for ParentGroupComponent
                            const isParentGroupComponent = component.type === FormComponentsType.ParentGroupComponent;
                            if (isChildAnswer && isParentGroupComponent) {
                                this.setParentGroupChildAnswers(component, localAnswer);
                            }

                            if (isSameComponent && !isParentGroupComponent) {
                                if (Array.isArray(answer) && settings.selectionStrategy === 'multiple') {
                                    answer.push(localAnswer);
                                } else {
                                    component.answer = localAnswer;
                                }
                            }
                        });
                    });
                }
            }
        }
    }

    public setParentGroupChildAnswers(component: IComponent, answer: IAnswer): void {
        const componentIndex = component.children.findIndex((child: IComponent) => child.id === answer.formQuestionId);
        component.children[componentIndex] = { ...component.children[componentIndex], answer };
    }

    public getColumnStyle(column: number): { [key: string]: string } | {} {
        if (Object.keys(this.theme).length) {
            if (this.currentBuilderPage.columns.length > 1) return column === 1 ? (this.theme as ITheme).leftColumn : (this.theme as ITheme).rightColumn;
            return (this.theme as ITheme)?.centralColumn || (this.theme as ITheme).rightColumn;
        }
        return {};
    }

    public triggerAlert(type: string, status: string, text: string): void {
        this.alertOptions = { type, status, text };
        this.alertVisibility = true;
        setTimeout(() => {
            this.alertVisibility = false;
        }, 2000);
    }

    public async undoPartialAnswerInStorage(): Promise<void> {
        try {
            const questions = this.getQuestion();

            let questionIds: number[] = this.questionsIdToRemove ?? [];
            const answersStorage = getAnswers(this.transactionReference, this.urlData!.formId) ?? [];

            answersStorage.forEach((answerList: IAnswer[], index: number) => {
                questions.forEach((item: IComponent) => {
                    if (!this.shouldShowItsSelf(item.conditionals) && answerList[0]?.formQuestionId === item.id && answersStorage[index].length > 0) {
                        answerList.forEach((answer: IAnswer) => (answer.answer = null));
                        item.answer = item.settings?.selectionStrategy != 'multiple' ? {} : [];

                        questionIds.push(+item.id);
                    }
                });
            });

            if (questionIds.length > 0) {
                const newAnswerStorage = answersStorage.filter((item: IAnswer[]) => item.every((answer: IAnswer): boolean => answer.answer != null));
                const objPartialAnswersToRemove = {
                    formId: +this.urlData.formId,
                    transactionId: +this.urlData.transactionId,
                    questionIds,
                };

                setAnswers(this.transactionReference, newAnswerStorage, this.urlData!.formId);
                await this.formService.deletePartialAnswers(objPartialAnswersToRemove);
            }
        } catch (error) {
            console.error('Error to delete partial answers', error);
            throw error;
        }
    }
}
