
import WrapperFormQuestion from '@/app/Survey/components/WrapperFormQuestion.vue';
import QuestionMixin from '@/app/Survey/FormComponents/QuestionMixin';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
    components: {
        WrapperFormQuestion,
    },
})
export default class ImageComponent extends Mixins(QuestionMixin) {}
